<template>
  <div>
    <h2>OvO - Cinema map assets</h2>
    <div class="card">
      <p>Cinema is one map I'm developing for an upcoming S&Box gamemode, known as OvO (One versus One).</p>
      <p>Below are some of the assets I've created, so far, for the new map.</p>
      <div
        v-for="(media, index) in getMedia('wsky_ovo_models')"
        :key="media.src + index"
        :class="index % 2 ? 'even' : 'odd'"
        style="display: inline"
      >
        <a v-if="media.type === 'image'" href="#" @click="previewSrc = media.src">
          <img :src="media.src">
        </a>
      </div>
    </div>
    <image-preview v-model="previewSrc" />
  </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview'

export default {
  components: { ImagePreview },
  data () {
    return {
      previewSrc: null,
      imageSections: {
        'wsky_ovo_models': {
          count: 3,
          folder: 'wsky_ovo_models'
        }
      }
    }
  },
  methods: {
    getMedia (id) {
      if (!id) return null

      let media = []
      for (let imageId = 1; imageId <= this.imageSections[id].count; imageId++) {
        // console.log(`./assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        media.push({
          type: 'image',
          src: require(`@/assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        })
      }

      if (this.imageSections[id].videos) {
        this.imageSections[id].videos.forEach(videoId => {
          media.push({
            type: 'video',
            src: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.mp4`),
            thumb: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.jpg`)
          })
        })
      }

      return media
    }
  }
}
</script>
